import {
  LinkTemplateMessageTypes as MessageTypes,
  LinkTemplateMessages,
} from './link-template.messages';
import { ILinkTemplateEntityState } from './interfaces';
import { adapter, initialState, paginationAdapter } from './link-template.adapter';
import { combineReducers } from '@ngrx/store';
import { ILinkTemplateEntity } from '../../interfaces/api/link-template-entity';
import { IPaginatedEntityState } from '@locumsnest/core/src/lib/interfaces/pagination';

export * from './interfaces';

export const paginationReducer = paginationAdapter.createReducer();

export type ILinkTemplatePaginatedEntityState = ILinkTemplateEntityState &
  IPaginatedEntityState<ILinkTemplateEntity>;

export function entityReducer(
  state = initialState,
  action: LinkTemplateMessages
): ILinkTemplateEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    default: {
      return state;
    }
  }
}

export function paginatedEntityStateReducer(
  state: ILinkTemplatePaginatedEntityState,
  action: LinkTemplateMessages
) {
  let pagination;

  if (state) {
    pagination = state.pagination;
  }

  return {
    ...entityReducer(state, action),
    pagination: {
      ...paginationReducer(pagination, action),
    },
  };
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export function reducer(state, action) {
  return combineReducers({
    entityState: paginatedEntityStateReducer,
  })(state, action);
}
