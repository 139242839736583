import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src/';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';
import { ILinkTemplateEntity } from '../../interfaces/api/link-template-entity';

export function sortByName(a: ILinkTemplateEntity, b: ILinkTemplateEntity): number {
  return a.id - b.id;
}

export const adapter: EntityAdapter<ILinkTemplateEntity> = createEntityAdapter<ILinkTemplateEntity>(
  {
    sortComparer: sortByName,
  }
);

export const messageableFactory = MessageableFactory.forFeature<'LinkTemplate'>('LinkTemplate');
export const signalableFactory = SignalableFactory.forFeature<'LinkTemplate'>('LinkTemplate');
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const loadingAdapter = createLoadingStateAdapter<'LinkTemplate'>(messageableFactory);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedLinkTemplateId: null,
});
