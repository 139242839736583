import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { ILinkTemplateEntity } from '../../interfaces/api/link-template-entity';
import { paginationAdapter } from './link-template.adapter';

export const LinkTemplatePaginationMessages = paginationAdapter.getMessages();

export class UpsertLinkTemplatePageMessage extends LinkTemplatePaginationMessages.UpsertPageMessage {}
export class UpsertLinkTemplateMultiplePagesMessage extends LinkTemplatePaginationMessages.UpsertMultiplePagesMessage {}
export class ResetLinkTemplatePaginationMessage extends LinkTemplatePaginationMessages.ResetPaginationMessage {}

export enum LinkTemplateSignalTypes {
  LOAD_LINK_TEMPLATES = '[LinkTemplate][Signal] Load Collection',
}
export enum LinkTemplateMessageTypes {
  SET_COLLECTION = '[LinkTemplate][Message] Set Collection',
  ADD_ONE = '[LinkTemplate][Message] Add One',
  UPDATE_ONE = '[LinkTemplate][Message] Update One',
  UPSERT_ONE = '[LinkTemplate][Message] Upsert One',
  DELETE_ONE = '[LinkTemplate][Message] Delete One',
  ADD_MULTIPLE = '[LinkTemplate][Message] Add All',
  DELETE_MULTIPLE = '[LinkTemplate][Message] Delete Many',
  UPSERT_MULTIPLE = '[LinkTemplate][Message] Upsert Many',
  UPDATE_MULTIPLE = '[LinkTemplate][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = LinkTemplateMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: ILinkTemplateEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = LinkTemplateMessageTypes.ADD_ONE;

  constructor(public payload: { entity: ILinkTemplateEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = LinkTemplateMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: ILinkTemplateEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = LinkTemplateMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: ILinkTemplateEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = LinkTemplateMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: ILinkTemplateEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = LinkTemplateMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<ILinkTemplateEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = LinkTemplateMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<ILinkTemplateEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = LinkTemplateMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = LinkTemplateMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type LinkTemplateMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
